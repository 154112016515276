import React, {Component} from 'react';

import {Input, Button, Form, Row, Col} from 'reactstrap'
import axios from 'axios';


export default class SpeakForm extends Component{
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state={
            words:'Please type here',
            processMessage: 'Please type your words...',
            hidePlayer: true,
            hideCreateButton: false
        }
      
    }

    handleSubmit(event){
     //  this.setState({processMessage:'Processing...'})
     event.preventDefault()
        console.log('The value submitted is '+this.state.words )
        this.setState(
            {
                processMessage:'I am converting...',
                hidePlayer: true,
                hideCreateButton: true
            })
        
        //make call and set state
        axios.post('https://api-polly.digitaldivvy.com/app/post',{name:'app',description:this.state.words})
        .then(response=>{
            console.log(JSON.stringify(response))
            this.setState({
                url: response.data.url,
                processMessage: 'Making an audio mp3',
               
            })
            
        })
        .catch(exception=>{
            console.error('Issue '+exception)
        })
        
        
        
        //Let the 
        setTimeout(()=>
        {
            this.setState(
                {
                hidePlayer: false, 
                processMessage: 'Ready to Play!'})
        },1500)
        
       
    }

    handleChange(event){
       // this.setState({processMessage:'Waiting for user...'})
       this.setState({words: event.target.value, processMessage: 'You are typing...', hideCreateButton: false});
       console.log('Change detected '+event.target.value)
    }
 render(){
        return (
              
                    <div className="jumbotron">
                        <Row><Col>
							<div className="page-header">
								<h2>Type text for Polly to read ! </h2>
                                <div>{this.state.processMessage}</div>
                              
                                <Form onSubmit={this.handleSubmit}>
                                <div className="card">
                                <textarea   maxLength="800" 
                                            type= "textarea"
                                            rows='5'
											placeholder="Type here" 
											className="form-control" 
                                            onChange={this.handleChange}
                                            />
                              
                              </div>
                              <div id="CreateButton" hidden={this.state.hideCreateButton}> 
                                <Button variant="outline-success">Create</Button>
                                </div>
                                </Form>
                                </div>
                                </Col></Row>

                        <Row>
                              
                      <Col>     <div id='audioPlayer' hidden={this.state.hidePlayer}>
                            <audio controls src={this.state.url} preload='none'></audio>
                          <p>    <a href={this.state.url}>Download  MP3</a></p>
                         </div>  </Col>
                      
                      

                   
                    </Row>
                    </div>          
                    
      
        )
    }
}
