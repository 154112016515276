import React, { Component } from 'react';

import './App.css';
import SpeakForm from './SpeakForm';
import {Container} from 'reactstrap';
import Header from './Header';
import Footer from './Footer';



class App extends Component {
  render() {
    return (
      <div className="App">
  <Container>
<Header></Header>
  <SpeakForm></SpeakForm>
</Container>
<Footer/>
      </div>
    );
  }
}

export default App;
