import React, {Component} from 'react';

import logo from './parrot.svg';
import {
    Row,
    Col,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';


class Header extends Component{

    render(){
        return(
            <Row>

            <Col>
            <p className="small">Made with love for Arianna, Mishika and Nik over the 2018 winter holidays. Built with ReactJS, API Gateway, Lambda, SQS, DynamoDB, S3, CertManager, AWS Polly and full stack development in TypeScript. 
            </p>
            </Col>
            
            </Row>
        );
    }
}

export default Header;