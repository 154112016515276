import React, {Component} from 'react';

import logo from './parrot.svg';
import {
    Row,
    Col,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';


class Header extends Component{

    render(){
        return(
            <Row>
            <Col> </Col>
            <Col>
            <div className="page-header">
            <img src={logo} width="30%" height="auto" alt="Logo" />
            </div>
            </Col>
            
            </Row>
        );
    }
}

export default Header;